<template>
    <modal ref="modalRecuperarPassword" titulo="Recuperar contraseña" adicional="Enviar" :cargando="loading" :desactivar-guardar="false" :no-aceptar="true" @adicional="guardar">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|email" name="correo">
                        <label class="pl-2 text-gris f-15"> Correo </label>
                        <el-input v-model="model.correo" placeholder="Correo" type="text" maxlength="1201" class="w-100 area-radius mb-3" />
                        <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import AuthService from '~/services/auth'
export default {
    data(){
        return {
            loading: false,
            model:{
                correo: null
            },
        }
    },
    methods: {
        toggle(){
            this.limpiarForm()
            this.$refs.modalRecuperarPassword.toggle();
        },
        limpiarForm(){
            this.model.correo = ''
            this.$refs.validacion.reset()
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                const {data: {data} }= await AuthService.recuperarPassword(this.model)
                this.loading = false
                this.notificacion('Mensaje', 'Contraseña enviada al correo', 'success')
                this.$refs.modalRecuperarPassword.toggle();

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>